const apiURL = "https://api.bigblueocean.net"
const siteURL = "https://portal.bigblueocean.net"


export {  
  apiURL,
  siteURL,
  };

export default {
  apiURL,
  siteURL
  
};
