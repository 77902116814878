import { HomeOutlined, FallOutlined, ClockCircleOutlined, UsergroupAddOutlined, HourglassOutlined, EyeOutlined,
DollarOutlined , FileTextOutlined,CalendarOutlined, SettingOutlined, UploadOutlined, StarOutlined, LineChartOutlined, MessageOutlined,
    WarningOutlined
} from '@ant-design/icons';

const SideBarLinks = [
    { key:0, url: "/",             name:"Home",             icon: HomeOutlined,         permissions:[],     subdir: [] },
   { key:10, url: "/salesstats", name:"Sales Stats",     icon: DollarOutlined,     permissions:[], subdir: [] },
    { key:1, url: "/revisions",    name:"Revisions",        icon: FallOutlined,         permissions:[],     subdir: [] },
    { key:2, url: "/aging",        name:"Aging",            icon: ClockCircleOutlined,  permissions:["AR"],     subdir: [] },
    { key:5, url: "/customers",    name:"Customer List",    icon: UsergroupAddOutlined, permissions:[], subdir: [] },
    { key:6, url: "/inactives",    name:"Inactive List",    icon: HourglassOutlined,    permissions:[], subdir: [] },
    { key:14, url: "/invoices",    name:"Invoices",         icon: FileTextOutlined,      permissions:[], subdir: [] },
    { key:14, url: "/shipmenttracker",    name:"Shipment Tracker",         icon: StarOutlined,      permissions:["SHIPMENTS"], subdir: [] },
    { key:9, url: "/users",        name:"Users",            icon: UploadOutlined,       permissions:["USERS"], subdir: [] },
    { key:10, url: "/dailyorders", name:"Daily Orders",     icon: CalendarOutlined,     permissions:["DAILYORDER"], subdir: [] },
   // { key:12, url: "/uploads",     name:"Uploads",          icon: UploadOutlined,       permissions:[], subdir: [] },
   // { key:13, url: "/messages",    name:"Messages",         icon: MessageOutlined,      permissions:[], subdir: [] },
  //  { key:15, url: "/supply",      name:"Supply Calendar",  icon: SettingOutlined,      permissions:[], subdir: [] },
]

const CustomerSideBarLinks = [ 
    { key:0, url: "/",             name:"Home",             icon: HomeOutlined,         permissions:[],     subdir: [] },
    { key:14, url: "/invoices",    name:"Invoices",         icon: FileTextOutlined,      permissions:[], subdir: [] },
    
]


export  {SideBarLinks,CustomerSideBarLinks}
