import * as React from "react";
import { Empty } from "antd";



const Dashboard = React.lazy(() => import("views/Dashboard"));
const Aging = React.lazy(() => import("pages/Aging"));
const Invoice = React.lazy(() => import("views/Invoice/page"));
const CustomerList = React.lazy(() => import("pages/Customers/list"));
const CustomerPage = React.lazy(() => import("pages/Customers/page"));
const InactiveList = React.lazy(() => import("pages/Inactives"));
const InvoiceList = React.lazy(() => import("views/Invoice/list"));
const Supply = React.lazy(() => import("views/Supply"));
const Users = React.lazy(()=> import("views/Users"))
const Revisions = React.lazy(() => import("pages/Revisions"));
const Login = React.lazy(() => import("pages/Login"))
const DailyOrders = React.lazy(() => import("views/Daily"))
const Messages = React.lazy(() => import("views/Messages"))
const IssueTracker = React.lazy(()=>import("views/IssueTracker"))
const ShipmentTracker = React.lazy(()=>import("views/PurchaseOrders/list"))
const PDF = React.lazy(() => import("views/PDFs/invoice"))
const SalesStats = React.lazy(() => import("views/SalesStats"))


const MainRoutes = [
    {
        url: "/",
        navbarKey:"/",            
        name:"Home",         
        component: Dashboard,  
        index:true,  
        permissions: [], 
        bc:  false 
    },{
        url: "/login",      
        name:"Login",         
        component: Login ,     
        index:false, 
        navbarKey:"/login",      
        permissions: [],  
        bc : [{ href:"", name:"Login" }]  },
    {
        url: "/revisions",  
        name:"Revisions",     
        component: Revisions,  
        index:false, 
        navbarKey:"/revisions",  
        permissions: [],  
        bc :  [{ href:"", name:"Revisions" }]  
    },
    {
        url: "/aging",      
                name:"Aging",         
                component: Aging,  
                index:false, 
                navbarKey:"/aging",      
                permissions: ["ADMIN","AR"], 
                bc :  [{ href:"", name:"Aging" }]  
    },
    {
        url: "/dilution",   
        name:"Dilution",      
        component: ()=><h2>Dilution </h2>,  
        index:false, navbarKey:"/dilution",   
        permissions: ["DILUTION"], 
        bc :  [{ href:"", name:"Dilution" }]  },
    {   
        url: "/customers",  
        name:"Customer List", 
        component: CustomerList,  
        index:false, 
        navbarKey:"/customers",  
        permissions: [], 
        bc :  [{ href:"", name:"Customers" }]  
    },
    {
        url: "/salesstats",
        name:"Sales Stats",
        component: SalesStats,
        index:false,
        navbarKey:"/salesstats",
        permissions: [],
        bc:  false
    },
    {
        url: "/customers/:customer",  
        name:"Customer Page", 
        component: CustomerPage,  
        index:false, 
        navbarKey:"/customers",  
        permissions: [], 
        bc:[
            { href:"/customers", name:"Customers" },
            { href:"/customers", name:"customer", dynamic: true }
        ]  
    },{
        url: "/inactives",  
        name:"Inactive List", 
        component: InactiveList,  
        index:false, 
        navbarKey:"/inactives",  
        permissions: [], 
        bc:[
            { href:"/customers", name:"Customers" },
            { href:"", name:"Inactives" }
        ]  
    },
    {
        url: "/watchlist",  
        name:"Watch List",    
        component: ()=><h2>Watchlist </h2>,  
        index:false, 
        navbarKey:"/watchlist",  
        permissions: [], 
        bc :  [{ href:"", name:"Watchlist" }]  
    },
    {
        url: "/notelog",    
        name:"Note Log",      
        component: ()=><h2>Note Log </h2>,  
        index:false, navbarKey:"/notelog",    
        permissions: [], 
        bc :  [{ href:"", name:"Notelog" }]  
    },{
        url: "/users",      
        name:"Users",         
        component: Users,  
        index:false, navbarKey:"/users",      
        permissions: ["USERS"], 
        bc :  [{ href:"", name:"Users" }]  
    },{
        url: "/dailyorders",
        name:"Daily Orders",  
        component: DailyOrders,  
        index:false, navbarKey:"/dailyorders",      
        permissions: ["DILUTION"], 
        bc : false, 
        fullscreen:true  
    },{
        url: "/issuetracker",
        name:"Issue Tracker",
        component: IssueTracker,  
        index:false, navbarKey:"/issuetracker",      
        permissions: ["DILUTION"], 
        bc : [{ href:"", name:"Issues" }] 
    },
    ,{
        url: "/shipmenttracker",
        name:"Shipment Tracker",
        component: ShipmentTracker,  
        index:false, navbarKey:"/shipmenttracker",      
        permissions: ["SHIPMENTS"], 
        bc : [{ href:"", name:"Shipments" }] 
    },{
        url: "/invoices",   
        name:"Invoices",      
        component:InvoiceList,  
        index:true, navbarKey:"/invoices",      
        permissions: [], 
        bc :  [{ href:"", name:"Invoices" }]  
    },{
        url: "/messagesss",   
        name:"Messages",    
        component:Messages,  
        index:true, navbarKey:"/messages",      
        permissions: [], 
        bc :  [{ href:"", name:"Messages" }]  
    },{
        url: "/invoices/:invoice",
        name:"Invoice", 
        component: Invoice,    
        index:false, 
        navbarKey:"/customers",      
        permissions: [], 
        bc:[
            { href:"/invoices", name:"Invoices" },
            { href:"/invoices", name:"invoice", dynamic: true }
        ]  
    },{
        url: "/supply",
        name:"Supply Calendar",    
        component: Supply ,  
        index:false, navbarKey:"/supply",      
        permissions: [], 
        bc :  [{ href:"", name:"Supply Calendar" }]  
    },{
        url: "/*",  
        name:"",     
        component: ()=><Empty description="This page doesn't exist!" />,  
        index:false, navbarKey:"/n",  permissions: [], bc :  false  
    },
]

const CustomerRoutes = [
    {url: "/",           name:"Home",          component: Dashboard,  index:true,  navbarKey:"/",           permissions: [] , bc :  false },
    {url: "/invoices",   name:"Invoices",     component:InvoiceList,  index:true, navbarKey:"/invoices",      permissions: [], bc :  [{ href:"", name:"Invoices" }]  },
    {url: "/invoices/:invoice",name:"Invoice", component: Invoice,    index:false, navbarKey:"/customers",      permissions: [], bc :  [{ href:"/invoices", name:"Invoices" },{ href:"/invoices", name:"invoice", dynamic: true }]  },
    {url: "/*",  name:"",     component: ()=><Empty description="This page doesn't exist!" />,  index:false, navbarKey:"/n",  permissions: [], bc :  false  }, 
]




export  {MainRoutes,CustomerRoutes}
